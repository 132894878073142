<template>
  <div>
    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-10">
        <h2>Compras</h2>
        <ol class="breadcrumb">
          <li>
            Cotizaciones
          </li>
          <li>
            <strong>
              {{modo == 'nuevo'?'Nuevo':''}}
              {{modo == 'edicion'?'Edición':''}}
              {{modo == 'visor'?'Visor':''}}
            </strong>
          </li>
        </ol>
      </div>
    </div>

    <div class="wrapper wrapper-content">
      <div class="row">
        <div class="col-md-12">
          <div class="ibox">
            <div class="ibox-title">
              <h5>
                {{modo == 'nuevo'?'Nueva cotizacion para':''}}
                {{modo == 'edicion'?'Editar cotización para':''}}
                {{modo == 'visor'?'Ver cotización para':''}}
                requerimiento
              </h5>
              <span class="label" style="font-size:12px">1035</span>
              <div class="ibox-tools">
                <a @click="cerrar()">
                  <i class="fa fa-close"></i>
                </a>
              </div>
            </div>
            
            <div class="ibox-content">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>Nombre del Artículo o servicio</th>
                          <th>División de familia de artículo</th>
                          <th>Cantidad solicitada</th>
                          <th>Proveedore</th>
                          <th style="min-width:100px">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            LiteBeam 5 AC Gen2
                          </td>
                          <td>
                            Equipo
                          </td>
                          <td>
                            2
                          </td>
                          <td>
                            <div class="checkbox checkbox-success checkbox-circle">
                              <input id="proveedor" type="checkbox">
                              <label for="proveedor">Proveedor principal</label>
                            </div>
                            <select class="form-control">
                              <option >Todos</option>
                              <option >Cancelado</option>
                            </select>
                            <div class="checkbox checkbox-success checkbox-circle">
                              <input id="cuadro" type="checkbox">
                              <label for="cuadro">Cuadro comparativo</label>
                            </div>
                          </td>
                          <td>
                            <div class="row">
                              <div class="col-md-4">
                                <label>Precio unitario</label>
                                <div class="input-group">
                                  <span class="input-group-addon">
                                    $
                                  </span>
                                  <input type="number" class="form-control"/>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <label>Tiempo de entrega</label>
                                <textarea rows="3" class="form-control"/>
                              </div>
                              <div class="col-md-4">
                                <label>Observaciones</label>
                                <textarea rows="3" class="form-control"/>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            LiteBeam 5 AC Gen2
                          </td>
                          <td>
                            Equipo
                          </td>
                          <td>
                            2
                          </td>
                          <td>
                            <div class="checkbox checkbox-success checkbox-circle">
                              <input id="proveedor" type="checkbox">
                              <label for="proveedor">Proveedor principal</label>
                            </div>
                            <select class="form-control">
                              <option >Todos</option>
                              <option >Cancelado</option>
                            </select>
                            <div class="checkbox checkbox-success checkbox-circle">
                              <input id="cuadro" type="checkbox">
                              <label for="cuadro">Cuadro comparativo</label>
                            </div>
                          </td>
                          <td>
                            <div class="row">
                              <div class="col-md-4">
                                <label>Precio unitario</label>
                                <div class="input-group">
                                  <span class="input-group-addon">
                                    $
                                  </span>
                                  <input type="number" class="form-control"/>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <label>Tiempo de entrega</label>
                                <textarea rows="3" class="form-control"/>
                              </div>
                              <div class="col-md-4">
                                <label>Observaciones</label>
                                <textarea rows="3" class="form-control"/>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            LiteBeam 5 AC Gen2
                          </td>
                          <td>
                            Equipo
                          </td>
                          <td>
                            2
                          </td>
                          <td>
                            <div class="checkbox checkbox-success checkbox-circle">
                              <input id="proveedor" type="checkbox">
                              <label for="proveedor">Proveedor principal</label>
                            </div>
                            <select class="form-control">
                              <option >Todos</option>
                              <option >Cancelado</option>
                            </select>
                            <div class="checkbox checkbox-success checkbox-circle">
                              <input id="cuadro" type="checkbox">
                              <label for="cuadro">Cuadro comparativo</label>
                            </div>
                          </td>
                          <td>
                            <div class="row">
                              <br><br>
                              <div class="col-md-12">
                                <button class="btn btn-primary btn-block">Cargar información de cuadro comparativo</button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-right">
            <button @click="cerrar()" class="btn btn-default">
              <i class="fa fa-angle-left"></i> Atras
            </button>

            {{modo == 'nuevo'?'&nbsp;':''}}

            <button
              type="button"
              class="btn btn-primary"
              @click="guardar()"
              v-if="modo == 'nuevo'"
            >
              <i class="fa fa-check"></i>
              Guardar
            </button>

            {{modo == 'edicion'?'&nbsp;':''}}

            <button
              type="button"
              class="btn btn-primary"
              @click="Actualizar()"
              v-if="modo == 'edicion'"
            >
              <i class="fa fa-check"></i>
              Actualizar
            </button>

            {{modo == 'visor'?'&nbsp;':''}}

            <button
              type="button"
              class="btn btn-danger"
              v-if="modo == 'visor'"
            >
              <i class="fa fa-times-circle"></i>
              Rechazar
            </button>

            {{modo == 'visor'?'&nbsp;':''}}

            <button
              type="button"
              class="btn btn-warning"
              v-if="modo == 'visor'"
            >
              <i class="fa fa-clock-o"></i>
              Posponer
            </button>

            {{modo == 'visor'?'&nbsp;':''}}

            <button
              type="button"
              class="btn btn-primary"
              v-if="modo == 'visor'"
            >
              <i class="fa fa-check"></i>
              Aprobar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArticuloSrv from '@/services/inventario/ArticuloSrv.js'
import FamiliaArticulosSrv from '@/services/inventario/FamiliaArticulosSrv.js'
import SistemaSrv from '@/services/SistemaSrv.js'
import accounting from 'accounting/accounting.js'

export default {
  data: function() {
    return {
      modo: 'nuevo',
    }
  },

  created: function() {
    var self = this

    // Determinar el modo de la vista
    if (self.$route.path.indexOf('cotizaciones/nuevo') != -1) self.modo = 'nuevo'
    if (self.$route.path.indexOf('edicion') != -1) self.modo = 'edicion'
    if (self.$route.path.indexOf('visor') != -1) self.modo = 'visor'
    
    switch(self.modo){
      case 'nuevo': {
        
      }
      break;
      case 'edicion': {

      }
      break;
      case 'visor': {

      }
    }
  },

  methods: {
    atras: function() {
      this.$router.go(-1)
    },

    cerrar: function() {
      this.atras()
    },
  }
}
</script>

<style>

</style>